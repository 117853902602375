<template>
  <div>
    <div class="authortitle">
      投稿<span @click="back()" style="float: left; cursor: pointer"
        ><i class="el-icon-arrow-left"></i
      ></span>
    </div>
    <div>
      <div class="search">
        <el-input
          placeholder="请输入内容"
          v-model="formdata.keywords"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchqk"
          ></el-button>
        </el-input>
      </div>
      <div>
        <el-collapse accordion>
          <el-collapse-item>
            <template slot="title">
              <div class="qkfl">
                <b>学术分类:</b>
                <span
                  :class="flnow == '' ? 'sortactive' : ''"
                  style="height: 20px; line-height: 20px"
                  @click="check('')"
                  >不限</span
                >
                <span
                  v-for="item in fl.slice(0, 15)"
                  style="height: 20px; line-height: 20px"
                  :key="item.id"
                  :class="flnow == item.id ? 'sortactive' : ''"
                  @click="check(item.id)"
                  >{{ item.types }}</span
                >
              </div>
            </template>
            <div class="qkfl">
              <span
                v-for="item in fl.slice(13, fl.length)"
                :class="flnow == item.id ? 'sortactive' : ''"
                :key="item.id"
                @click="check(item.id)"
                >{{ item.types }}</span
              >
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-collapse accordion>
          <el-collapse-item>
            <template slot="title">
              <div class="qkfl">
                <b>期刊收录:</b>
                <span
                  :class="slnow == '' ? 'sortactive' : ''"
                  style="height: 20px; line-height: 20px"
                  @click="check2('', '')"
                  >不限</span
                >
                <span
                  v-for="item in sl.slice(0, 6)"
                  style="height: 20px; line-height: 20px"
                  :class="slnow == item.id ? 'sortactive' : ''"
                  :key="item.id"
                  @click="check2(item.id, item.number)"
                  >{{ item.include }}</span
                >
              </div>
            </template>
            <div class="qkfl">
              <span
                v-for="item in sl.slice(6, sl.length)"
                :class="slnow == item.id ? 'sortactive' : ''"
                :key="item.id"
                @click="check2(item.id, item.number)"
                >{{ item.include }}</span
              >
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div>
      <ul class="displayqk">
        <li
          v-for="item in qikan"
          @click="linktodetail(item.qikan_id)"
          :key="item.id"
          style="cursor: pointer"
        >
          <img :src="item.cover" alt="" class="images" />
          <span>{{ item.qk_name }}</span>
        </li>
      </ul>
    </div>
    <div class="orderPage">
      <el-pagination
        class="fy"
        small
        @size-change="handleSizeChange"
        :page-size="pagesize"
        :page-sizes="[8, 20, 30]"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="current_change"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>
       
	<script>
import {
  showallqikan,
  Academic,
  Include,
  Classification,
} from "../../../API/ZZD/magzine.js";
export default {
  data() {
    return {
      qikan: [],
      total: 0, //默认数据总数
      pagesize: 8, //每页的数据条数
      loading: false,
      currentPage: 1, //默认开始页面
      flnow: "",
      slnow: "",
      slnum: "",
      fl: [],
      formdata: {
        keywords: "",
      },
      sl: [],
    };
  },
  mounted() {
    this.searchqk();
    //期刊分类
    Academic().then((res) => {
      console.log(res);
      this.fl = res.data.data;
    });
    //期刊收录
    Include().then((res) => {
      console.log(res);
      this.sl = res.data.data;
    });
  },
  methods: {
    check(e) {
      this.flnow = e;
      this.searchqk();
      event.stopPropagation();
    },

    check2(e, num) {
      this.slnow = e;
      this.slnum = num;
      this.searchqk();
      event.stopPropagation();
    }, // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.searchqk();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.searchqk();
    }, //跳转详情
    linktodetail(qkid) {
      this.$router.push({
        path: "/Pending/contribute/detail",
        query: { qkid: qkid },
      });
    }, //查询期刊信息
    showqikan() {
      showallqikan(this.currentPage, this.pagesize).then((res) => {
        console.log(res);
        this.qikan = res.data.data;
        this.total = res.data.total;
        for (let i = 0; i < this.qikan.length; i++) {
          this.qikan[i].cover = this.URL + this.qikan[i].cover;
        }
      });
    }, //查询分类收录的期刊
    searchqk() {
      Classification(
        this.formdata.keywords,
        this.currentPage,
        this.pagesize,
        this.flnow,
        this.slnum
      ).then((res) => {
        console.log(res);
        this.qikan = res.data.data;
        this.total = res.data.total;
        for (let i = 0; i < this.qikan.length; i++) {
          this.qikan[i].cover = this.URL + this.qikan[i].cover;
        }
      });
    },
    back() {
      this.$router.push({ path: "/Pending/contribute" });
    },
  },
};
</script>


	<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.qkfl {
  padding: 20px;
  box-sizing: border-box;
}
.qkfl span {
  display: inline-block;
  padding: 0 5px;
  text-align: center;
  cursor: pointer;
  margin: 0 3px;
}
.sortactive {
  background-color: #ff4318;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  padding: 0 5px;
  text-align: center;
  cursor: pointer;
  margin: 0 3px;
}
.displayqk {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
}
.displayqk li {
  width: 22%;
  margin: 10px 10px;
  text-align: center;
}
.displayqk li img {
  width: 100%;
  height: 80%;
}
.orderPage {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search {
  display: flex;
  width: 70%;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin-top: 20px;
}
</style>
<style>
.el-collapse-item__header {
  min-height: 48px;
  height: auto;
}
.search .el-input-group .el-input__inner {
  border: none !important;
  height: 38px;
  border-radius: 20px;
}
.search .el-input-group .el-input-group__append {
  background: none;
  border: none;
  color: #75a1ea;
  font-size: 20px;
  font-weight: bold;
}
</style>